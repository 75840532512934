<template>
  <div
    :title="node.data.props.clickable ? 'Select step' : 'Step already exists'"
    :class="['tile', `node-${node.id}`, node.data.props.clickable ? 'clickable' : 'not-clickable']"
    @click="onClick()"
  >
    <div class="title">
      <div class="task-title">{{ node.data.props.title }}</div>
    </div>
    <div class="statuses-wrapper">
      <div v-for="(input, index) in inputs()" :key="input.key" class="statuses">
        <p-socket v-socket:input="input" type="input" :socket="input.socket" />

        <p-socket
          v-socket:output="outputs()[index]"
          type="output"
          :socket="outputs()[index].socket"
          :is-connected="outputs()[index].connections.length"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Socket from './Socket.vue';

export default {
  components: {
    'p-socket': Socket
  },
  directives: {
    socket: {
      beforeMount(el, binding) {
        binding.instance.bindSocket(el, binding.arg, binding.value);
      },
      updated(el, binding) {
        binding.instance.bindSocket(el, binding.arg, binding.value);
      }
    },
    control: {
      beforeMount(el, binding) {
        if (!binding.value) return;
        binding.instance.bindControl(el, binding.value);
      }
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    isEventSource: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: null
    },
    editor: {
      type: Object,
      default: null
    },
    bindSocket: {
      type: Function,
      default: null
    },
    bindControl: {
      type: Function,
      default: null
    }
  },

  data() {
    return {};
  },
  computed: {},
  methods: {
    onClick() {
      if (this.node.data.props.clickable) {
        this.node.data.props.onItemClick(this.node.id);
      }
    },
    inputs() {
      return Array.from(this.node.inputs.values());
    },
    outputs() {
      return Array.from(this.node.outputs.values());
    },
    controls() {
      return Array.from(this.node.controls.values());
    },
    selected() {
      return this.editor.selected.contains(this.node) ? 'selected' : '';
    }
  }
};
</script>
<style lang="scss" scoped>
.tile {
  border: 2px solid white;
  background-color: var(--theme-surface);
  border: 2px solid var(--theme-on-background);
  border-radius: 5px;
  cursor: pointer;

  &.not-clickable {
    border-color: var(--theme-error);
    cursor: not-allowed;
  }

  .title-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--theme-on-background);
  }
  .title {
    text-align: center;
    padding: 5px;
    max-width: 200px;
    width: 200px;
    font-size: 1rem;
    .for-board {
      display: block;
      span {
        padding: 10px 12px;
      }
    }
  }
  .statuses {
    display: flex;
    justify-content: space-between;
  }
}
</style>
